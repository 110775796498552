<template>
  <div v-if="items">
    <div v-if="showCancelActionButton || showReturnActionButton" class="mb-8">
      <v-btn
        v-if="showCancelActionButton"
        color="green"
        class="mr-4"
        outlined
        @click="applyCancelReturnRequest('cancel')"
      >
        {{'order.action.apply_cancel_request'| t}}
      </v-btn>

      <v-btn
        v-if="showCancelActionButton"
        color="orange"
        class="mr-4"
        outlined
        @click="rejectCancelReturnRequest('cancel')"
      >
        {{'order.action.reject_cancel_request'| t}}
      </v-btn>

      <v-btn
        v-if="showReturnActionButton"
        color="green"
        class="mr-4"
        outlined
        @click="applyCancelReturnRequest('return')"
      >
        {{'order.action.apply_return_request'| t}}
      </v-btn>

      <v-btn
        v-if="showReturnActionButton"
        color="orange"
        class="mr-4"
        outlined
        @click="rejectCancelReturnRequest('return')"
      >
        {{'order.action.reject_return_request'| t}}
      </v-btn>
    </div>

    <productItem
      v-for="(item, index) in items"
      :form-key="formKey"
      :key="item.id"
      :item="item"
      :index="index"
    ></productItem>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  methods: {
    applyCancelReturnRequest(actionType) {
      let popupConfig = {
        title: this.$t(`order.action.apply_${actionType}_request`),
        actionType,
        applyOnEnter: false,
        applyCallback: data => this.applyCancelReturnRequestAction(actionType, data),
      }
      if(actionType === 'return') {
        popupConfig.bodySlot = () => import('./applyCancelReturnRequestPopup.vue')
      }
      this.$apopup.base(popupConfig)
    },
    async applyCancelReturnRequestAction(actionType, data) {
      let params = {}
      if(data && data.status) {
        params.status = data.status
      }
      try {
        await this.$api.collection.orderApi[`${actionType}RequestApply`](this.orderId, params)
        this.$snotify.success(null, this.$t(`order.notify.apply_${actionType}_request.successfully`))
        this.formActions.initForm()
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t(`error.unexpect`),
          this.$t(`order.notify.apply_${actionType}_request.failure`)
        )
        throw error
      }
    },
    rejectCancelReturnRequest(actionType) {
      let popupConfig = {
        title: this.$t(`order.action.reject_${actionType}_request`),
        actionType,
        applyOnEnter: false,
        applyCallback: data => this.rejectCancelReturnRequestAction(actionType, data),
      }
      if(actionType === 'return') {
        popupConfig.bodySlot = () => import('./rejectCancelReturnRequestPopup.vue')
      }
      this.$apopup.base(popupConfig)
    },
    async rejectCancelReturnRequestAction(actionType, data) {
      let params = {}
      if(data && data.status) {
        params.status = data.status
      }
      try {
        await this.$api.collection.orderApi[`${actionType}RequestReject`](this.orderId, params)
        this.$snotify.success(null, this.$t(`order.notify.reject_${actionType}_request.successfully`))
        this.formActions.initForm()
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t(`error.unexpect`),
          this.$t(`order.notify.reject_${actionType}_request.failure`)
        )
        throw error
      }
    },
  },
  computed: {
    orderId() {
      if(!this.formData) return null
      return this.formData.id
    },
    items() {
      if(!this.formData) return null
      if(!Array.isArray(this.formData.items)) return null
      return this.formData.items
    },
    showCancelActionButton() {
      if(!this.formData) return false
      return this.formData.status == this.$orderConstants.STATUS_CANCEL_REQUEST
    },
    showReturnActionButton() {
      if(!this.formData) return false
      return this.formData.status == this.$orderConstants.STATUS_RETURN_REQUEST
    },
  },
  components: {
    productItem: () => import('./productItem.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
