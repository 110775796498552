import { render, staticRenderFns } from "./items.vue?vue&type=template&id=31e98be3&scoped=true&"
import script from "./items.vue?vue&type=script&lang=babel&"
export * from "./items.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e98be3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
